<template>
  <el-dialog
    v-model="showRuleDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-automation-rule-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-automation-rule-dialog-header__title">
        {{ props.title }}
      </h4>
    </template>
    <el-scrollbar
      v-if="showRuleDialog"
      ref="ruleScrollbarRef"
      v-loading="ruleDialogLoading"
      max-height="calc(100vh - 320px)"
      wrap-class="elv-automation-rule-dialog-scrollbar__wrap"
    >
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-position="top">
        <el-form-item :label="t('title.ruleName')" prop="name">
          <el-input v-model.trim="ruleForm.name" :placeholder="t('message.pleaseInput')" class="elv-rule-form-name" />
        </el-form-item>
        <el-form-item
          v-if="props.activeTab === 'derivativeAdjustment'"
          :label="t('report.dataSource')"
          prop="derivativeType"
        >
          <SingleChoiceSelect
            v-model="ruleForm.derivativeType"
            :placeholder="`${t('common.select')}...`"
            class="elv-rule-form-name"
            width="802px"
            :options="derivativeTypeOptions"
            :popper-append-to-body="false"
            @change="onChangeDerivativeType"
          />
        </el-form-item>

        <el-form-item
          v-if="['businessData', 'valuation'].includes(props.activeTab)"
          :label="t('common.dataName')"
          prop="businessDataTypeId"
        >
          <SingleChoiceSelect
            v-model="ruleForm.businessDataTypeId"
            filterable
            :placeholder="`${t('common.select')}...`"
            class="elv-rule-form-name"
            width="802px"
            :options="businessDataTypeOptions"
            :popper-append-to-body="false"
            @change="onChangeBusinessData"
          />
        </el-form-item>

        <el-form-item :label="t('common.conditions')" class="elv-automation-rule-form-item-line condition">
          <div v-if="showRuleDialog" class="elv-rule-form-condition">
            <div class="elv-rule-form-condition-title">{{ t('common.selectConditionsInfo') }}</div>
            <template v-if="['businessData', 'valuation'].includes(props.activeTab)">
              <BusinessDataColumn
                v-for="(item, index) in conditionList"
                ref="businessDataColumnRef"
                :key="item.index"
                :index="index"
                :condition="item"
                :model="props.model"
                :columnOptions="currentBusinessData.columns || []"
                :conditionTypeDisabled="conditionList.length === 1 && !ruleForm.businessDataTypeId"
                :currencyList="props.currencyList"
                :conditionList="conditionList"
                @addCondition="addCondition"
                @onConditionChange="onConditionChange"
              />
            </template>

            <template v-else>
              <Conditions
                v-for="(item, index) in conditionList"
                :key="item.index"
                :index="index"
                :condition="item"
                :model="props.model"
                :activeTab="activeTab"
                :derivativeType="ruleForm.derivativeType"
                :derivateOptions="props.derivateOptions"
                :currencyList="props.currencyList"
                :conditionList="conditionList"
                @addCondition="addCondition"
                @onConditionChange="onConditionChange"
              />
            </template>
          </div>
        </el-form-item>
        <template v-if="!['counterpartyTransformer', 'valuation'].includes(props.activeTab)">
          <el-form-item
            :label="
              ['derivativeAdjustment', 'businessData'].includes(props.activeTab)
                ? t('report.actionCreateJournal')
                : t('report.actionJournal')
            "
            class="elv-automation-rule-form-item-line"
            :class="{ 'is-businessData': ['derivativeAdjustment', 'businessData'].includes(props.activeTab) }"
            prop="journalTypeId"
          >
            <div :class="{ 'elv-automation-rule-form-item__multiple': isGeneralJournalType }">
              <div class="elv-rule-form-event">
                <div
                  v-if="!['derivativeAdjustment', 'businessData'].includes(props.activeTab)"
                  class="elv-rule-form-condition-title"
                >
                  {{ t('title.createJournal') }}
                </div>
                <div v-else class="elv-rule-form-condition-journal-type__title">
                  {{ t('report.journalType') }}
                </div>

                <el-popover
                  ref="journalTypeSelectRef"
                  placement="bottom-start"
                  trigger="click"
                  popper-class="elv-journal-type-drawer-category-popper"
                  :show-arrow="true"
                  :offset="0.2"
                  width="650px"
                  @before-enter="onCheckSelectArrow(true)"
                  @before-leave="onCheckSelectArrow(false)"
                >
                  <template #reference>
                    <div class="elv-journal-type-drawer-category-select">
                      <div v-if="ruleForm.journalTypeId && currentJournalTypeData?.name">
                        <span>{{
                          locale === 'en'
                            ? currentJournalTypeData?.name
                            : currentJournalTypeData?.nameCN || currentJournalTypeData?.name
                        }}</span>
                      </div>
                      <p v-else class="is-placeholder">{{ t('common.select') }} ...</p>
                      <SvgIcon name="events-arrow" width="16" height="16" :style="{ transform: arrowRotate }" />
                    </div>
                  </template>
                  <JournalTypeOverlayDropdown
                    v-model="ruleForm.journalTypeId"
                    :showGeneralJournal="props.activeTab === 'businessData'"
                    :dropDownData="journalTypeFilterList"
                    @onChangeJournalTypeSelect="onCloseJournalTypeSelect"
                    @onCreateGeneralJournal="onCreateGeneralJournal"
                  />
                </el-popover>
              </div>

              <el-form-item
                v-if="isGeneralJournalType"
                :label="t('common.activity')"
                prop="journalTypeGroup"
                class="elv-rule-form-general-journal__type"
              >
                <SingleChoiceSelect
                  v-model="ruleForm.journalTypeGroup"
                  :placeholder="`${t('common.select')}...`"
                  width="262px"
                  :options="reportTransactionType"
                  :popper-append-to-body="false"
                />
              </el-form-item>
            </div>
          </el-form-item>

          <SupplementaryJournalInfo
            v-if="props.activeTab === 'businessData' && ruleForm.journalTypeId && ruleForm.businessDataTypeId"
            v-model:timezone="ruleForm.timezone"
            v-model:datetime="ruleForm.datetime"
            v-model:referenceNo="ruleForm.referenceNo"
            v-model:additionalItem="ruleForm.additionalItem"
            :columnOptions="businessDataColumnOptions"
          />

          <el-form-item
            v-if="ruleForm.journalTypeId && (!isCompoundEntry || props.activeTab !== 'transfer')"
            :label="props.activeTab !== 'businessData' ? t('report.actionEntry') : t('title.entryLines')"
            class="elv-automation-rule-form-item-line"
            :class="{ 'is-businessData-entry': props.activeTab === 'businessData' }"
          >
            <DerivativeAdjustmentActionEntry
              v-if="props.activeTab === 'derivativeAdjustment'"
              ref="ruleEntryLinesRef"
              v-model:entryLineAuxiliaryValueMap="entryLineAuxiliaryValueMap"
              v-loading="previewLoading"
              :loading="previewLoading"
              :model="props.model"
              :entryLines="entryLines"
              :derivativeType="ruleForm.derivativeType"
              :isGeneralJournalType="isGeneralJournalType"
              :amountFormulaList="amountFormulaList"
              :journalTypeData="currentJournalTypeData"
              :auxiliaryTypeList="props.auxiliaryTypeList"
              @onChangeSelect="onChangeSelectValue"
            />

            <RuleActionEntry
              v-else-if="props.activeTab !== 'businessData'"
              ref="ruleEntryLinesRef"
              v-model:entryLineAuxiliaryValueMap="entryLineAuxiliaryValueMap"
              v-loading="previewLoading"
              :loading="previewLoading"
              :entryLines="entryLines"
              :activeTab="activeTab"
              :isGeneralJournalType="isGeneralJournalType"
              :conditionType="activeTab === 'trade' ? conditionList[0]?.value || '' : ''"
              :amountFormulaList="amountFormulaList"
              :journalTypeData="currentJournalTypeData"
              :auxiliaryTypeList="props.auxiliaryTypeList"
              @onChangeSelect="onChangeSelectValue"
            />

            <BusinessDataEntryLines
              v-else
              ref="ruleEntryLinesRef"
              v-model:entryLineAuxiliaryValueMap="entryLineAuxiliaryValueMap"
              v-loading="previewLoading"
              :loading="previewLoading"
              :entryLines="entryLines"
              :model="props.model"
              :amountFormulaList="amountFormulaList"
              :currencyList="props.currencyList"
              :currentBusinessData="currentBusinessData"
              :isGeneralJournalType="isGeneralJournalType"
              :journalTypeData="currentJournalTypeData"
              :auxiliaryTypeList="props.auxiliaryTypeList"
              @onChangeSelect="onChangeSelectValue"
              @onAddEntry="onAddEntry"
              @onDeleteEntry="onDeleteEntry"
              @onChangeChartOfAccount="onChangeChartOfAccount"
            />
          </el-form-item>
        </template>

        <el-form-item
          v-if="ruleForm.businessDataTypeId && props.activeTab === 'valuation'"
          :label="t('report.actionCreateValuationData')"
          class="elv-automation-rule-form-item-line is-valuation"
        >
          <SupplementaryValuationData
            v-model:derivativeType="ruleForm.derivativeType"
            v-model:action="ruleForm.action"
            :columnOptions="businessDataColumnOptions"
          />
        </el-form-item>

        <el-form-item
          v-if="props.activeTab === 'counterpartyTransformer'"
          :label="t('report.actionIdentifyCounterparty')"
          class="elv-automation-rule-form-item-line is-valuation"
        >
          <SupplementaryIdentifyCounterparty
            v-model:counterpartyField="ruleForm.counterpartyField"
            v-model:identificationCodeName="ruleForm.identificationCodeName"
            v-model:extractRegex="ruleForm.extractRegex"
            v-model:sensitive="ruleForm.sensitive"
            :identifyCounterpartyNameList="identifyCounterpartyNameList"
          />
        </el-form-item>
      </el-form>
    </el-scrollbar>

    <template #footer>
      <el-button
        type="primary"
        round
        class="elv-automation-rule-dialog-footer__save"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="onSaveAutomationRule"
        >{{ t('button.save') }}</el-button
      >
    </template>
  </el-dialog>

  <ElvMessageBox
    ref="ruleMessageBoxRef"
    :show-cancel-button="true"
    :cancel-button-text="t('button.cancel')"
    :confirm-button-text="t('button.save')"
    :loading="submitLoading"
    :title="t('title.conditionsHaveChanged')"
    @onConfirmEvent="onSaveAutomationRule"
    @onCancelEvent="onCancelSaveRule"
  >
    <template #content>
      <span class="elv-confirm-info-header__title">
        {{ t('message.conditionsHaveChangedInfo') }}
      </span>
    </template>
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus'
import ValuationApi from '@/api/ValuationApi'
import Conditions from '../Conditions/Index.vue'
import { CurrencyItemType } from '#/ReportsTypes'
import TransactionsApi from '@/api/TransactionsApi'
import BusinessDataApi from '@/api/BusinessDataApi'
import RuleActionEntry from './RuleActionEntry.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'
import BusinessDataColumn from './BusinessDataColumn.vue'
import type { FormInstance, FormRules } from 'element-plus'
import { reportTransactionType } from '@/config/reports/index'
import { BusinessDataTypeItemType } from '#/BusinessDataTypes'
import BusinessDataEntryLines from './BusinessDataEntryLines.vue'
import { useTransactionStore } from '@/stores/modules/transactions'
import SupplementaryJournalInfo from './SupplementaryJournalInfo.vue'
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'
import SupplementaryValuationData from './SupplementaryValuationData.vue'
import JournalTypeOverlayDropdown from '../../JournalTypeOverlayDropdown.vue'
import DerivativeAdjustmentActionEntry from './DerivativeAdjustmentActionEntry.vue'
import SupplementaryIdentifyCounterparty from './SupplementaryIdentifyCounterparty.vue'
import { find, pick, isArray, uniqBy, filter, isNumber, isEmpty, cloneDeep } from 'lodash-es'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  title: {
    type: String,
    default: ''
  },
  activeTab: {
    type: String,
    default: ''
  },
  currencyList: {
    type: Array<CurrencyItemType>,
    required: true
  },
  auxiliaryTypeList: {
    type: Array<any>,
    required: true
  },
  derivateOptions: {
    type: Array as () => ElvSelectOptionType[],
    default: () => {
      return []
    }
  },
  currentRuleData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t, locale } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const transactionStore = useTransactionStore()
const emit = defineEmits(['resetList'])

const journalTypeSelectRef = ref()
const ruleMessageBoxRef = ref()
const ruleScrollbarRef = ref()
const businessDataColumnRef: any = ref([])
const submitLoading = ref(false)
const showRuleMessageBox = ref(false)
const showRuleDialog = ref(false)
const ruleDialogLoading = ref(false)
const previewLoading = ref(false)
const ruleEntryLinesRef = ref()
const identifyCounterpartyNameList = ref<string[]>([])
const formulaFieldMapData: any = ref([])
const businessDataTransformerData: any = ref({})
const arrowRotate = ref('rotate(90deg)')
const ruleForm: any = ref({
  name: '',
  journalTypeId: '',
  businessDataTypeId: '',
  timezone: 'UTC',
  datetime: '',
  referenceNo: '',
  journalTypeGroup: '',
  additionalItem: []
})
const ruleFormRef = ref<FormInstance>()
const defaultCurrency = unref(entityStore.entityDetail.defaultCurrency)

const derivativeTypeOptions = reactive([
  { value: 'FUTURE', label: 'Future-Unrealized P&L Adjustment' },
  { value: 'OPTION', label: 'Options-Unrealized P&L Adjustment' },
  { value: 'VALIDATOR', label: 'Validator Staking - Staking Rewards' }
])

const rules = reactive<FormRules>({
  name: {
    required: true,
    trigger: 'blur',
    message: 'Rule Name is required'
  },
  derivativeType: {
    required: true,
    trigger: 'blur',
    message: 'Data Source is required'
  },
  conditions: {
    required: true,
    trigger: 'blur',
    message: 'Conditions is required'
  },
  businessDataTypeId: {
    required: true,
    trigger: 'blur',
    message: 'Data Name is required'
  },
  journalTypeId: {
    required: true,
    trigger: 'blur',
    message: 'Journal Type is required'
  },
  journalTypeGroup: {
    required: true,
    trigger: 'blur',
    message: 'Activity is required'
  },
  timezone: {
    required: true,
    trigger: 'blur',
    message: 'Timezone is required'
  },
  datetime: {
    required: true,
    trigger: 'blur',
    message: 'DateTime is required'
  }
})

const conditionList: any = ref([
  {
    index: '0',
    operator: 'EQ',
    type: '',
    value: ''
  }
])

const entryLineAuxiliaryValueMap: any = ref([[], []])
const businessDataTypeList = ref<BusinessDataTypeItemType[]>([])
const entryLines: any = ref([])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const generalJournalTypeId = computed(() => {
  const generalJournal: any = find(transactionStore.journalTypeList, { slug: 'general_journal' })
  return generalJournal?.journalTypeId ?? ''
})

const currentJournalTypeData = computed(() => {
  let data: any = {}
  data = find(transactionStore.journalTypeList, { journalTypeId: ruleForm.value.journalTypeId })
  return data
})

const currentChartOfAccount: any = useComputedHook((chartOfAccountId: string) => {
  return find(entityStore.chartOfAccountList, { chartOfAccountId })
})

const businessDataTypeOptions = computed(() => {
  return businessDataTypeList.value.map((item: any) => {
    return {
      label: item.name,
      value: item.businessDataTypeId
    }
  })
})

const currentBusinessData: any = computed(() => {
  return find(businessDataTypeList.value, { businessDataTypeId: ruleForm.value.businessDataTypeId }) || {}
})

const isGeneralJournalType = computed(() => {
  if (ruleForm.value.journalTypeId) {
    return ruleForm.value.journalTypeId === generalJournalTypeId.value
  }
  return false
})

const amountFormulaList = computed(() => {
  let parameters: any = []
  if (props.activeTab === 'transfer') {
    return []
  }

  switch (props.activeTab) {
    case 'businessData':
      if (ruleForm.value.businessDataTypeId && currentBusinessData.value?.columns?.length) {
        currentBusinessData.value.columns.forEach((column: string, index: number) => {
          parameters.push({
            var: `f${String(index + 1).padStart(3, '0')}`,
            type: 'BUSINESS_FIELD',
            value: index,
            label: column
          })
        })
      }
      parameters.push({
        var: `f${String(parameters.length + 1).padStart(3, '0')}`,
        type: 'CURRENCY_PRICE',
        value: '',
        label: 'currency_price'
      })
      entryLines.value.forEach((entryLine: any, index: number) => {
        parameters.push({
          var: `f${String(parameters.length + 1).padStart(3, '0')}`,
          type: 'ENTRY_INDEX',
          value: index,
          attribute: 'AMOUNT_FC',
          label: `#${index + 1} Amount(${defaultCurrency})`
        })
      })
      parameters.push({
        var: `f${String(parameters.length + 1).padStart(3, '0')}`,
        type: 'COST_BASIS_OF_SALES',
        value: '',
        label: `Cost basis of sales`
      })
      // 兼容老数据的公式字段
      if (props.model === 'edit' && formulaFieldMapData.value.length) {
        // 是否为完全相等
        const isExactlyEqual = formulaFieldMapData.value.every((formulaField: any) => {
          const index = parameters.findIndex(
            (parameter: any) =>
              parameter.var === formulaField.var &&
              parameter.type === formulaField.type &&
              parameter.value === formulaField.value
          )
          return index !== -1
        })
        // 如果完全相等则直接返回
        if (isExactlyEqual) return parameters
        let merged: any = []
        formulaFieldMapData.value.forEach((formulaField: any) => {
          const data = cloneDeep(formulaField)
          // 查找 parameter 中的匹配项
          const parameterIndex = parameters.findIndex(
            (parameter: any) => parameter.type === formulaField.type && parameter.value === formulaField.value
          )
          if (parameterIndex !== -1) {
            data.label = parameters[parameterIndex].label
            // 删除 parameters 中的匹配项
            parameters.splice(parameterIndex, 1)
          }
          merged.push(data)
        })

        merged = merged.concat(parameters)

        let counter = formulaFieldMapData.value.length + 1
        merged.forEach((item: any) => {
          if (!formulaFieldMapData.value.some((formulaField: any) => formulaField.var === item.var)) {
            item.var = `f${String(counter).padStart(3, '0')}`
            counter += 1
          }
        })

        // 重新排号
        merged = merged.map((item: any, index: number) => {
          item.var = `f${String(index + 1).padStart(3, '0')}`
          item.label = item?.label?.trim() || item.label
          return item
        })
        return merged
      }
      break
    case 'trade':
      parameters = [
        {
          var: `f001`,
          type: 'BASE_AMOUNT_FC',
          value: '',
          label: `Base amount(${defaultCurrency})`
        },
        {
          var: `f002`,
          type: 'COUNTER_AMOUNT_FC',
          value: '',
          label: `Counter amount(${defaultCurrency})`
        },
        {
          var: `f003`,
          type: 'FEE_AMOUNT_FC',
          value: '',
          label: `Fee amount(${defaultCurrency})`
        }
      ]
      entryLines.value.forEach((entryLine: any, index: number) => {
        parameters.push({
          var: `f${String(parameters.length + 1).padStart(3, '0')}`,
          type: 'ENTRY_INDEX',
          value: index,
          attribute: 'AMOUNT_FC',
          label: `#${index + 1} Amount(${defaultCurrency})`
        })
      })
      parameters.push({
        var: `f${String(parameters.length + 1).padStart(3, '0')}`,
        type: 'COST_BASIS_OF_SALES',
        value: '',
        label: `Cost basis of sales`
      })
      break
    case 'gainLoss':
      parameters.push({
        var: `f${String(parameters.length + 1).padStart(3, '0')}`,
        type: 'AMOUNT_FC',
        value: '',
        label: `Amount(${defaultCurrency})`
      })
      entryLines.value.forEach((entryLine: any, index: number) => {
        parameters.push({
          var: `f${String(parameters.length + 1).padStart(3, '0')}`,
          type: 'ENTRY_INDEX',
          value: index,
          attribute: 'AMOUNT_FC',
          label: `#${index + 1} Amount(${defaultCurrency})`
        })
      })
      parameters.push({
        var: `f${String(parameters.length + 1).padStart(3, '0')}`,
        type: 'COST_BASIS_OF_SALES',
        value: '',
        label: `Cost basis of sales`
      })
      break
    case 'derivativeAdjustment':
      parameters = [
        {
          var: `f001`,
          type: 'AMOUNT',
          value: '',
          label: `${ruleForm.value?.derivativeType === 'VALIDATOR' ? t('valuation.validator.rewardsChange') : t('valuation.future.unrealizedAndChange')}`
        },
        {
          var: `f002`,
          type: 'AMOUNT_FC',
          value: '',
          label: `${ruleForm.value?.derivativeType === 'VALIDATOR' ? t('valuation.validator.rewardsChange') : t('valuation.future.unrealizedAndChange')} (${defaultCurrency})`
        }
      ]
      entryLines.value.forEach((entryLine: any, index: number) => {
        parameters.push({
          var: `f${String(parameters.length + 1).padStart(3, '0')}`,
          type: 'ENTRY_INDEX',
          value: index,
          attribute: 'AMOUNT_FC',
          label: `#${index + 1} Amount(${defaultCurrency})`
        })
      })
      parameters.push({
        var: `f${String(parameters.length + 1).padStart(3, '0')}`,
        type: 'COST_BASIS_OF_SALES',
        value: '',
        label: `Cost basis of sales`
      })
      break
    default:
      break
  }

  return parameters
})

const businessDataColumnOptions: any = computed(() => {
  if (isEmpty(currentBusinessData.value)) return []
  return currentBusinessData.value?.columns?.map((item: string, index: number) => {
    return {
      label: item,
      value: index
    }
  })
})

const onChangeBusinessData = () => {
  ruleForm.value.datetime = ''
  ruleForm.value.referenceNo = ''
  ruleForm.value.additionalItem = []

  conditionList.value = [
    {
      index: '0',
      operator: 'EQ',
      column: '',
      columnType: '',
      type: '',
      value: ''
    }
  ]

  businessDataColumnRef.value?.[0]?.onResetColumn()
  if (entryLines.value.length) {
    entryLines.value.forEach((item: any) => {
      item.currency = []
      item.amount = []
      item.amountFC = []
    })
    entryLineAuxiliaryValueMap.value = entryLineAuxiliaryValueMap.value.map((auxiliary: any) => {
      return auxiliary.map((item: any) => {
        return {
          ...item,
          value: '',
          data: []
        }
      })
    })
  }
}

const onChangeDerivativeType = () => {
  conditionList.value = [
    {
      index: '0',
      operator: 'EQ',
      type: '',
      value: ''
    }
  ]
}

// 是否为多借多贷
const isCompoundEntry = computed(() => {
  return currentJournalTypeData.value?.entryType === 'COMPOUND'
})

const conditionValueFormat = computed(() => {
  return (data: any) => {
    if (
      (['ENDING_DATE', 'DATETIME'].includes(data.type) || data?.columnType === 'DATETIME') &&
      data.operator !== 'BETWEEN'
    ) {
      return isArray(data.value) ? data.value : [data.value]
    }
    if (['AMOUNT', 'AMOUNT_FC'].includes(data?.type) || data?.columnType === 'NUMBER') {
      return data.value.map((item: any) => Number(item))
    }
    if (data?.type === 'PLATFORM') {
      return data.value.platformIds.map((item: any) => {
        return { platformId: item }
      })
    }
    if (data?.type === 'ASSET') {
      return data.value.map((item: any) => {
        return { assetId: item }
      })
    }
    if (data?.type === 'ENTITY_ACCOUNT') {
      return data.value.entityAccountIds.map((item: any) => {
        return { entityAccountId: item }
      })
    }
    if (data?.type === 'COUNTERPARTY') {
      return data.value.counterpartyIds.map((item: any) => {
        return { counterpartyId: item }
      })
    }
    if (data?.type === 'COUNTERPARTY_TAG') {
      return data.value.counterpartyTagIds.map((item: any) => {
        return { counterpartyTagId: item }
      })
    }
    return data.value
  }
})

// 检查conditionList是否符合条件
const isBusinessDataConditionMet = computed(() => {
  if (['businessData', 'valuation'].includes(props.activeTab)) {
    return conditionList.value.every((item: any) => {
      if (item.columnType === 'CURRENCY_TYPE') {
        return item.column !== '' && item?.operator && item?.type
      }
      if (item.columnType === 'TEXT') {
        return (
          item.column !== '' &&
          item?.operator &&
          item?.value?.length &&
          item?.value?.every((value: any) => value !== '')
        )
      }
      return (
        item.column !== '' &&
        item.operator &&
        (isArray(item.value) ? item.value.every((value: any) => value !== '') : item.value) &&
        item?.columnType !== ''
      )
    })
  }
  return false
})

// BusinessData检查conditionList是否为空
const isBusinessDataSingleEmpty = computed(() => {
  return (
    conditionList.value.length === 1 &&
    conditionList.value[0].type === '' &&
    isArray(conditionList.value[0].value) &&
    (!conditionList.value[0].value?.length || !conditionList.value[0].value?.every((value: any) => !value)) &&
    conditionList.value[0].column === ''
  )
})

// 检查conditionList是否为空
const isConditionListSingleEmpty = computed(() => {
  return conditionList.value.length === 1 && conditionList.value[0].type === '' && conditionList.value[0].value === ''
})

const saveDisabled = computed(() => {
  if (previewLoading.value) return true

  // 检查entryLines含有GST的下标
  const hasGstTypeIndex: any = entryLines.value.findIndex(
    (i: any) => i?.gstType !== 'NONE' && i?.gstType !== 'NA' && i?.gstType !== '' && i?.gstType !== undefined
  )
  const hasGstType = hasGstTypeIndex !== -1

  if (props.activeTab === 'businessData') {
    // businessData检查entryLines是否符合条件
    const isEveryBusinessDataEntryLineValid = entryLines.value.every((item: any, index: number) => {
      const chartOfAccount = currentChartOfAccount(entryLines.value[index].chartOfAccountId).value
      // TREASURY_ACCOUNT必须添加Account辅助核算字段
      const isTreasuryAccount = item?.chartOfAccountCalculator === 'TREASURY_ACCOUNT'
      let isEmptyEntityAccount = false
      if (isTreasuryAccount) {
        const entityAccountData = find(entryLineAuxiliaryValueMap.value[index], { auxiliaryCode: 'ENTITY_ACCOUNT' })
        isEmptyEntityAccount =
          !entityAccountData?.data?.length || !entityAccountData?.data?.every((account: any) => account !== '')
      }
      // 是否为报告币种
      const isReporting = chartOfAccount?.currencyTypes?.[0] === 'REPORTING'
      return (
        item.chartOfAccountId &&
        item.currency.length &&
        ((item.amountFC?.length &&
          (hasGstTypeIndex === index || !hasGstType) &&
          item.amountFC.every((value: any) => value !== '')) ||
          (!item.amountFC?.length && hasGstTypeIndex !== index && hasGstType)) &&
        ((item.amount.length &&
          item.amount.every((value: any) => value !== '') &&
          (!isReporting || (hasGstType && hasGstTypeIndex === index))) ||
          isReporting ||
          (hasGstType && hasGstTypeIndex !== index)) &&
        (!isTreasuryAccount || !isEmptyEntityAccount)
      )
    })

    if (
      ruleForm.value?.name === '' ||
      ruleForm.value?.businessDataTypeId === '' ||
      ruleForm.value?.journalTypeId === '' ||
      ruleForm.value?.datetime === '' ||
      ruleForm.value?.referenceNo === '' ||
      ruleForm.value?.additionalItem?.length < 1
    ) {
      return true
    }

    // entryLines.value里至少有一个 Dr 和 Cr
    const dr = entryLines.value.some((item: any) => item.balanceType === 'Dr')
    const cr = entryLines.value.some((item: any) => item.balanceType === 'Cr')
    return (
      (!isBusinessDataConditionMet.value && !isBusinessDataSingleEmpty.value) ||
      !isEveryBusinessDataEntryLineValid ||
      entryLines.value.length < 2 ||
      !dr ||
      !cr
    )
  }

  if (props.activeTab === 'valuation') {
    const isActionMet =
      ruleForm.value?.action?.datetime !== '' &&
      ruleForm.value?.action?.timezone !== '' &&
      ruleForm.value?.action?.referenceNo !== '' &&
      ruleForm.value?.action?.type !== '' &&
      ruleForm.value?.action?.assetName !== '' &&
      ruleForm.value?.action?.currency !== '' &&
      ruleForm.value?.action?.amount !== '' &&
      (ruleForm.value?.action?.type === 'OPTION_REALIZED' || ruleForm.value?.action?.type === 'OPTION_UNREALIZED'
        ? true
        : ruleForm.value?.action?.positionSide !== '')
    return (
      ruleForm.value?.name === '' ||
      ruleForm.value?.businessDataTypeId === '' ||
      ruleForm.value?.derivativeType === '' ||
      (!isBusinessDataConditionMet.value && !isBusinessDataSingleEmpty.value) ||
      !isActionMet
    )
  }

  // 检查conditionList是否符合条件
  const encryptionIds = [
    'PLATFORM',
    'ASSET',
    'CURRENCY',
    'TRANSACTION_TYPE',
    'PLATFORM_TYPE',
    'ENTITY_ACCOUNT',
    'ENTITY_ACCOUNT_TAG',
    'COUNTERPARTY',
    'COUNTERPARTY_TAG',
    'COUNTERPARTY_IDENTITY'
  ]
  const isConditionMet = conditionList.value.every((item: any) => {
    if (encryptionIds.includes(item.type)) {
      const keys = Object.keys(item.value)
      return item.type && item.operator && !isEmpty(item.value[keys?.[0]])
    }
    if (['AMOUNT', 'AMOUNT_FC', 'ENDING_DATE', 'DATETIME'].includes(item.type)) {
      if (item.operator === 'BETWEEN') {
        return item.type && item.operator && item.value && item.value[0] !== '' && item.value[1] !== ''
      }
      return (
        item.type &&
        item.operator &&
        ((['AMOUNT', 'AMOUNT_FC'].includes(item.type) && item.value[0] !== '') ||
          (['ENDING_DATE', 'DATETIME'].includes(item.type) && item.value !== ''))
      )
    }
    return item.type && item.operator && item.value
  })

  if (props.activeTab === 'counterpartyTransformer') {
    return (
      !ruleForm.value.name ||
      !ruleForm.value.counterpartyField ||
      (!isConditionMet && !isConditionListSingleEmpty.value) ||
      !ruleForm.value.extractRegex ||
      (ruleForm.value.counterpartyField === 'IDENTIFICATION_CODE' && !ruleForm.value.identificationCodeName)
    )
  }

  // 检查entryLines是否符合条件
  const isEveryEntryLineValid = entryLines.value.every((item: any, index: number) => {
    const chartOfAccount = currentChartOfAccount(entryLines.value[index].chartOfAccountId).value
    // 是否为报告币种
    const isReporting = chartOfAccount?.currencyTypes?.[0] === 'REPORTING'
    return (
      item.chartOfAccountId &&
      ((item.currency && (!isReporting || (hasGstType && hasGstTypeIndex === index))) ||
        (!item.currency && (isReporting || (hasGstType && hasGstTypeIndex !== index)))) &&
      ((item.amount && (!isReporting || (hasGstType && hasGstTypeIndex === index))) ||
        (!item.amount && (isReporting || (hasGstType && hasGstTypeIndex !== index)))) &&
      ((item.amountFC?.length &&
        (hasGstTypeIndex === index || !hasGstType) &&
        item.amountFC.every((value: any) => value !== '')) ||
        (!item.amountFC?.length && hasGstTypeIndex !== index && hasGstType))
    )
  })

  return (
    !ruleForm.value.name ||
    !ruleForm.value.journalTypeId ||
    (!isConditionMet &&
      ((!isConditionListSingleEmpty.value && props.activeTab !== 'trade') || props.activeTab === 'trade')) ||
    (!isEveryEntryLineValid && props.activeTab !== 'transfer') ||
    (props.activeTab === 'derivativeAdjustment' && !ruleForm.value.derivativeType)
  )
})

const journalTypeFilterList = computed(() => {
  const list = transactionStore.journalTypeFilterList(props.activeTab === 'transfer' ? 'automation' : 'journal')
  return list
})

// 切换弹窗展示
const onCheckRuleDialog = () => {
  showRuleDialog.value = !showRuleDialog.value
}

const onCheckSelectArrow = (status: boolean) => {
  arrowRotate.value = status ? 'rotate(-90deg)' : 'rotate(90deg)'
}

const onCreateGeneralJournal = (id: string) => {
  ruleForm.value.journalTypeId = id
  if (isEmpty(props.currentRuleData) || props.model === 'edit') {
    unref(journalTypeSelectRef)?.hide?.()
    ruleForm.value.journalTypeId = id
  }
  entryLines.value = [
    {
      index: 0,
      chartOfAccountId: '',
      balanceType: 'Dr',
      gstType: '',
      currency: [],
      amount: [],
      amountFC: [],
      auxiliaryTypes: []
    },
    {
      index: 1,
      chartOfAccountId: '',
      balanceType: 'Cr',
      gstType: '',
      currency: [],
      amount: [],
      amountFC: [],
      auxiliaryTypes: []
    }
  ]
}

/**
 * @description: 辅助核算初始化
 * @param {ChartOfAccountItemType} chartOfAccount
 * @param {number} index
 */
const auxiliaryDataInit = (chartOfAccount: any, index: number) => {
  if (!chartOfAccount?.auxiliaryTypes?.length) {
    entryLineAuxiliaryValueMap.value[index] = []
    return
  }
  entryLineAuxiliaryValueMap.value[index] = chartOfAccount.auxiliaryTypes.map((auxiliary: any) => {
    const value: any = {
      type: ''
    }
    if (props.activeTab === 'businessData') {
      value.auxiliaryTypeId = auxiliary.auxiliaryTypeId
      value.value = ''
      value.data = []
      switch (auxiliary.type) {
        case 'ENTITY_ACCOUNT':
          value.auxiliaryCode = 'ENTITY_ACCOUNT'
          break
        case 'COUNTERPARTY':
          value.auxiliaryCode = 'COUNTERPARTY'
          break
        default:
          value.auxiliaryCode = 'ITEM'
          break
      }
      return value
    }
    switch (auxiliary.type) {
      case 'ENTITY_ACCOUNT':
        value.type = 'ENTITY_ACCOUNT'
        break
      case 'COUNTERPARTY':
        if (props.activeTab !== 'derivativeAdjustment') {
          value.type = props.activeTab !== 'transfer' ? 'ENTITY_ACCOUNT_COUNTERPARTY' : 'COUNTERPARTY'
        }
        break
      default:
        if (props.activeTab !== 'derivativeAdjustment') {
          value.type = 'AUXILIARY_ITEM'
        }
        break
    }

    return {
      auxiliaryTypeId: auxiliary.auxiliaryTypeId,
      value
    }
  })
}

/**
 * @description: 更改科目
 * @param {number} index
 */
const onChangeChartOfAccount = (index: number) => {
  const chartOfAccount = currentChartOfAccount(entryLines.value[index].chartOfAccountId).value
  entryLines.value[index].currency = []
  entryLines.value[index].amount = []
  entryLines.value[index].amountFC = []
  if (chartOfAccount?.currencyTypes?.[0] === 'REPORTING') {
    entryLines.value[index].currency = ['REPORTING_CURRENCY', entityStore.entityDetail.defaultCurrency]
  }
  entryLines.value[index].gstType = ''
  entryLines.value[index].auxiliaryTypes = chartOfAccount?.auxiliaryTypes ?? []
  entryLines.value[index].chartOfAccount = chartOfAccount ?? {}
  auxiliaryDataInit(chartOfAccount, index)
}

/**
 * @description: journalType辅助核算预览
 */
const journalTypeAuxiliaryPreview = async () => {
  try {
    previewLoading.value = true
    entryLines.value = []
    const { data } = await TransactionsApi.automationRuleAuxiliaryTypePreview(entityId.value, {
      journalTypeId: ruleForm.value.journalTypeId
    })
    entryLines.value = data.map((item: any, index: number) => {
      const entry = {
        index,
        ...item
      }
      const chartOfAccount = currentChartOfAccount(item?.chartOfAccountId).value
      entry.chartOfAccount = chartOfAccount ?? {}
      return entry
    })

    data.forEach((item: any, index: number) => {
      auxiliaryDataInit(item, index)
    })
    if (props.activeTab === 'businessData') {
      data.forEach((item: any, index: number) => {
        const chartOfAccount = currentChartOfAccount(item?.chartOfAccountId).value
        entryLines.value[index].currency = []
        entryLines.value[index].amount = []
        entryLines.value[index].amountFC = []
        if (
          chartOfAccount?.currencyTypes?.[0] === 'REPORTING' ||
          ['gst_input', 'gst_output'].includes(chartOfAccount?.slug)
        ) {
          entryLines.value[index].currency = ['REPORTING_CURRENCY', entityStore.entityDetail.defaultCurrency]
        }
        // Sale类型的journalType
        if (currentJournalTypeData.value?.brokerInventoryType === 'SALE') {
          entryLines.value[index].amountFC = ['COST_BASIS_OF_SALES']
        }
      })
    } else if (props.activeTab === 'derivativeAdjustment') {
      data.forEach((item: any, index: number) => {
        const chartOfAccount = currentChartOfAccount(item?.chartOfAccountId).value
        entryLines.value[index].currency = 'MARGIN_ASSET_CURRENCY'
        entryLines.value[index].amount = 'AMOUNT'
        entryLines.value[index].amountFC = []

        if (
          chartOfAccount?.currencyTypes?.[0] === 'REPORTING' ||
          ['gst_input', 'gst_output'].includes(chartOfAccount?.slug)
        ) {
          entryLines.value[index].amount = ''
          entryLines.value[index].currency = ''
        }

        // Sale类型的journalType
        if (currentJournalTypeData.value?.brokerInventoryType === 'SALE') {
          entryLines.value[index].amountFC = ['COST_BASIS_OF_SALES']
        }
      })
    } else if (props.activeTab !== 'transfer') {
      data.forEach((item: any, index: number) => {
        const chartOfAccount = currentChartOfAccount(item?.chartOfAccountId).value
        entryLines.value[index].currency = props.activeTab === 'gainLoss' ? 'GAIN_LOSS_ASSET' : ''
        entryLines.value[index].amount = props.activeTab === 'gainLoss' ? 'GAIN_LOSS_AMOUNT' : ''
        entryLines.value[index].amountFC = []

        if (
          chartOfAccount?.currencyTypes?.[0] === 'REPORTING' ||
          ['gst_input', 'gst_output'].includes(chartOfAccount?.slug)
        ) {
          entryLines.value[index].amount = ''
          entryLines.value[index].currency = ''
        }

        // Sale类型的journalType
        if (currentJournalTypeData.value?.brokerInventoryType === 'SALE') {
          entryLines.value[index].amountFC = ['COST_BASIS_OF_SALES']
        }
      })
    }
    return Promise.resolve()
  } catch (error) {
    console.log(error)
    return Promise.reject()
  } finally {
    previewLoading.value = false
  }
}

/**
 * @description:  初始化通用日记账类型
 * @param {Object} entryLine
 */
const generalJournalTypeInit = async (entryLine: any) => {
  entryLines.value = entryLine.map((item: any, index: number) => {
    const chartOfAccount = currentChartOfAccount(item?.chartOfAccountId).value
    const entry = {
      index,
      balanceType: item.balanceType,
      chartOfAccountId: item.chartOfAccountId,
      chartOfAccountCalculator: '',
      currency: [],
      amount: [],
      amountFC: [],
      chartOfAccount: chartOfAccount ?? {},
      auxiliaryTypes: chartOfAccount.auxiliaryTypes ?? []
    }
    auxiliaryDataInit(chartOfAccount, index)
    return entry
  })
}

/**
 * @description: 选择journalType关闭下拉框
 */
const onCloseJournalTypeSelect = () => {
  unref(journalTypeSelectRef)?.hide?.()
  if ((!isCompoundEntry.value && props.activeTab === 'transfer') || props.activeTab !== 'transfer') {
    journalTypeAuxiliaryPreview()
  }
}

/**
 * @description: 添加entry
 * @param {string} balanceType 借贷方向
 */
const onAddEntry = (balanceType: string) => {
  entryLines.value.push({
    index: entryLines.value.length,
    chartOfAccountId: '',
    balanceType,
    currency: [],
    amount: [],
    amountFC: [],
    auxiliaryTypes: []
  })
  entryLineAuxiliaryValueMap.value.push([])
}

/**
 * @description: 删除entry
 * @param {number} index
 */
const onDeleteEntry = (index: number) => {
  entryLines.value.splice(index, 1)
  entryLines.value.forEach((item: any, i: number) => {
    item.index = i
  })
  entryLineAuxiliaryValueMap.value.splice(index, 1)
}

/**
 * @description: 关闭弹窗
 */
const onCloseDialog = () => {
  ruleFormRef.value?.resetFields()
  conditionList.value = [
    {
      index: '0',
      operator: 'EQ',
      type: '',
      value: ''
    }
  ]
  entryLines.value = []
  entryLineAuxiliaryValueMap.value = [[], []]
}

// eslint-disable-next-line no-unused-vars
const processAmount = (item: any, data: any, formulaField: any, key: string) => {
  let formula = ''
  const formulaFieldMap = cloneDeep(formulaField)
  const amountType = item[key][0]
  const amountValue = item[key][1]
  let parameters

  if (amountType === 'BUSINESS_FIELD' || amountType === 'CURRENCY_PRICE') {
    parameters = find(amountFormulaList.value, {
      type: amountType,
      ...(amountType === 'BUSINESS_FIELD' && { value: amountValue })
    })
  } else if (isNumber(amountType)) {
    parameters = find(amountFormulaList.value, { type: 'ENTRY_INDEX', value: amountType })
  } else if (amountType === 'FORMULA') {
    formula = amountValue
    amountFormulaList.value.forEach((param: any) => {
      if (amountValue?.indexOf(param?.var) !== -1) {
        formulaFieldMap.push(param)
      }
    })
    // 早期返回以避免在FORMULA类型下执行不必要的操作
    return { formula, formulaFieldMap }
  }

  if (parameters) {
    formulaFieldMap.push(parameters)
    formula = parameters?.var
  }
  return { formula, formulaFieldMap }
}

/**
 * @description: entry辅助核算value格式化
 * @return {array} entryLineAuxiliaryValueList
 */
const entryLineAuxiliaryValueFormat = () => {
  const entryLineAuxiliaryValueList = entryLineAuxiliaryValueMap.value.map((item: any, index: number) => {
    return item.map((auxiliary: any) => {
      const types = ['NONE', 'ENTITY_ACCOUNT', 'COUNTERPARTY', 'AUXILIARY_ITEM', 'ENTITY_ACCOUNT_COUNTERPARTY']
      let { value } = auxiliary
      if (!types.includes(auxiliary.value.type)) {
        const auxiliaryTypes =
          entryLines.value?.[index]?.chartOfAccountCalculator === 'TREASURY_ACCOUNT'
            ? entryLines.value?.[index]?.auxiliaryTypes
            : currentJournalTypeData.value?.entryLines?.[index]?.chartOfAccount?.auxiliaryTypes
        const auxiliaryType = auxiliaryTypes?.find((data: any) => data?.auxiliaryTypeId === auxiliary?.auxiliaryTypeId)
        switch (auxiliaryType?.type) {
          case 'ENTITY_ACCOUNT':
            value = {
              entityAccountId: auxiliary.value.type,
              type: 'ENTITY_ACCOUNT'
            }
            break
          case 'COUNTERPARTY':
            value = {
              counterpartyId: auxiliary.value.type,
              type: 'COUNTERPARTY'
            }
            break
          case 'ITEM':
            value = {
              auxiliaryItemId: auxiliary.value.type,
              type: 'AUXILIARY_ITEM'
            }
            break
          default:
            value = {
              auxiliaryItemId: auxiliary.value.type,
              type: 'AUXILIARY_ITEM'
            }
            break
        }
      }
      return {
        auxiliaryTypeId: auxiliary.auxiliaryTypeId,
        value: value.type === 'NONE' ? { type: 'NONE' } : value
      }
    })
  })
  return entryLineAuxiliaryValueList
}

/**
 * @description: trade/gainLoss/derivativeAdjustment的entry格式化
 */
const otherTabEntryFormat = () => {
  const entryActions = entryLines.value.map((item: any, index: number) => {
    const entry: any = {
      chartOfAccountId: item.chartOfAccountId,
      balanceType: item?.balanceType,
      currency: item.currency,
      amount: item.amount,
      chartOfAccountCalculator: item?.chartOfAccountCalculator ?? '',
      amountFC: ''
    }
    const formulaFieldMap: any = []

    if (
      [
        'COST_BASIS_OF_SALES',
        'COUNTER_AMOUNT_FC',
        'FEE_AMOUNT_FC',
        'BASE_AMOUNT_FC',
        'BASE_AMOUNT_FC',
        'AMOUNT_FC',
        'AMOUNT'
      ].includes(item.amountFC[0])
    ) {
      const parameters = find(amountFormulaList.value, { type: item.amountFC[0] })
      formulaFieldMap.push(parameters)
      entry.amountFC = parameters?.var
    }
    if (isNumber(item.amountFC[0])) {
      const parameters = find(amountFormulaList.value, { type: 'ENTRY_INDEX', value: item.amountFC[0] })
      formulaFieldMap.push(parameters)
      entry.amountFC = parameters?.var
    }
    if (item.amountFC[0] === 'FORMULA') {
      // eslint-disable-next-line prefer-destructuring
      entry.amountFC = item.amountFC[1]
      amountFormulaList.value.forEach((parameters: any) => {
        if (item.amountFC[1]?.indexOf(parameters?.var) !== -1) {
          formulaFieldMap.push(parameters)
        }
      })
    }
    entry.formulaFieldMap = uniqBy(formulaFieldMap, 'var').map((formula: any) => {
      const data = { ...formula }
      delete data.label
      return data
    })
    entry.auxiliaryMapping = entryLineAuxiliaryValueFormat()?.[index] ?? []
    return entry
  })
  return entryActions
}

/**
 * @description: businessData的entry格式化
 */
const businessDataEntryFormat = () => {
  const entryActions = entryLines.value.map((item: any, index: number) => {
    const chartOfAccount = currentChartOfAccount(item?.chartOfAccountId).value
    const entry: any = {
      chartOfAccountId: item.chartOfAccountId,
      chartOfAccountCalculator: chartOfAccount?.calculator ?? item.chartOfAccountCalculator,
      balanceType: item?.balanceType,
      currencyType: item.currency[0],
      amount: '',
      amountFC: ''
    }
    const formulaFieldMap: any = []
    if (item.currency[0] !== 'REPORTING_CURRENCY') {
      entry.businessCurrency = item.currency[0] === 'BUSINESS_CURRENCY' ? item.currency[1] : ''
      entry.directCurrency = item.currency[0] === 'DIRECT_CURRENCY' ? item.currency[1] : ''
    }

    // TODO: 待优化
    if (item.amount[0] === 'BUSINESS_FIELD') {
      const parameters = find(amountFormulaList.value, { type: 'BUSINESS_FIELD', value: item.amount[1] })
      formulaFieldMap.push(parameters)
      entry.amount = parameters?.var
    }
    if (item.amount[0] === 'CURRENCY_PRICE') {
      const parameters = find(amountFormulaList.value, { type: item.amount[0] })
      formulaFieldMap.push(parameters)
      entry.amount = parameters?.var
    }
    // const amount = processAmount(item, entry, formulaFieldMap, 'amount')
    // const amountFC = processAmount(item, entry, formulaFieldMap, 'amountFC')
    // entry.amount = amount.formula
    // entry.amountFC = amountFC.formula
    // entry.formulaFieldMap = uniqBy(amount.formulaFieldMap.concat(amountFC.formulaFieldMap), 'var')

    if (isNumber(item.amount[0])) {
      const parameters = find(amountFormulaList.value, { type: 'ENTRY_INDEX', value: item.amount[0] })
      formulaFieldMap.push(parameters)
      entry.amount = parameters?.var
    }
    if (item.amount[0] === 'FORMULA') {
      // eslint-disable-next-line prefer-destructuring
      entry.amount = item.amount[1]
      amountFormulaList.value.forEach((parameters: any) => {
        if (item.amount[1]?.indexOf(parameters?.var) !== -1) {
          formulaFieldMap.push(parameters)
        }
      })
    }

    if (item.amountFC[0] === 'BUSINESS_FIELD') {
      const parameters = find(amountFormulaList.value, { type: 'BUSINESS_FIELD', value: item.amountFC[1] })
      formulaFieldMap.push(parameters)
      entry.amountFC = parameters?.var
    }
    if (['CURRENCY_PRICE', 'COST_BASIS_OF_SALES'].includes(item.amountFC[0])) {
      const parameters = find(amountFormulaList.value, { type: item.amountFC[0] })
      formulaFieldMap.push(parameters)
      entry.amountFC = parameters?.var
    }
    if (isNumber(item.amountFC[0])) {
      const parameters = find(amountFormulaList.value, { type: 'ENTRY_INDEX', value: item.amountFC[0] })
      formulaFieldMap.push(parameters)
      entry.amountFC = parameters?.var
    }
    if (item.amountFC[0] === 'FORMULA') {
      // eslint-disable-next-line prefer-destructuring
      entry.amountFC = item.amountFC[1]
      amountFormulaList.value.forEach((parameters: any) => {
        if (item.amountFC[1]?.indexOf(parameters?.var) !== -1) {
          formulaFieldMap.push(parameters)
        }
      })
    }
    entry.formulaFieldMap = uniqBy(formulaFieldMap, 'var').map((formula: any) => {
      const data = { ...formula }
      delete data.label
      return data
    })
    entry.auxiliaryMapping = entryLineAuxiliaryValueMap.value?.[index]
      ?.filter((i: any) => i?.data && !!i?.data?.length && i.data[0] !== '' && i.data[1] !== '')
      ?.map((auxiliary: any) => {
        const data: any = {
          auxiliaryCode: auxiliary.auxiliaryCode,
          auxiliaryTypeId: auxiliary.auxiliaryTypeId,
          type: auxiliary?.data[0] ?? '',
          value: auxiliary?.data[1] ?? ''
        }
        switch (auxiliary.auxiliaryCode) {
          case 'ENTITY_ACCOUNT':
            data.value = {
              entityAccountId: auxiliary?.data[1] ?? ''
            }
            break
          case 'COUNTERPARTY':
            data.value = {
              counterpartyId: auxiliary?.data[1] ?? ''
            }
            break
          default:
            data.value = {
              auxiliaryItemId: auxiliary?.data[1] ?? ''
            }
            break
        }
        if (data.type === 'PATH') {
          data.value = {
            columnIndex: auxiliary?.data[1] ?? ''
          }
        }
        return data
      })
    return entry
  })
  return entryActions
}

/**
 * @description: 添加自动化规则
 * @param {Object} params
 * @returns {Promise}
 */
const onAddAutomationRule = async (params: any) => {
  try {
    switch (props.activeTab) {
      case 'transfer':
        await TransactionsApi.addAutomationRule(entityId.value, params)
        break
      case 'businessData':
        await BusinessDataApi.createBusinessDataAutomationRule(entityId.value, params)
        break
      case 'trade':
        await TransactionsApi.addTradeAutomationRule(entityId.value, params)
        break
      case 'gainLoss':
        await TransactionsApi.addGainLossAutomationRule(entityId.value, params)
        break
      case 'valuation':
        await TransactionsApi.addDerivativeFactTransformerRule(entityId.value, params)
        break
      case 'derivativeAdjustment':
        await ValuationApi.addDerivativeAdjustmentAutomationRule(entityId.value, params)
        break
      case 'counterpartyTransformer':
        await TransactionsApi.addCounterpartyTransformerRule(entityId.value, params)
        break
      default:
        break
    }
    ElMessage.success(t('message.ruleSavedSuccessfully'))
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

const onCancelSaveRule = () => {
  showRuleMessageBox.value = false
  businessDataTransformerData.value = {}
  ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * @description: 编辑自动化规则
 * @param {Object} data
 * @returns {Promise}
 */
const onEditAutomationRule = async (data: any) => {
  const params = cloneDeep(data)
  try {
    switch (props.activeTab) {
      case 'transfer':
        delete params.type
        await TransactionsApi.editAutomationRule(entityId.value, props.currentRuleData?.automationRuleId, params)
        break
      case 'trade':
        await TransactionsApi.editTradeAutomationRule(
          entityId.value,
          props.currentRuleData?.tradeAutomationRuleId,
          params
        )
        break
      case 'gainLoss':
        await TransactionsApi.editGainLossAutomationRule(
          entityId.value,
          props.currentRuleData?.gainLossAutomationRuleId,
          params
        )
        break
      case 'businessData':
        await BusinessDataApi.editBusinessDataAutomationRule(
          entityId.value,
          props.currentRuleData?.businessDataAutomationRuleId,
          params
        )
        break
      case 'valuation':
        if (!showRuleMessageBox.value) {
          showRuleMessageBox.value = true
          businessDataTransformerData.value = params
          ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
          return Promise.resolve()
        }
        await TransactionsApi.editDerivativeFactTransformerRule(
          entityId.value,
          props.currentRuleData?.derivativeFactTransformerId,
          params
        )
        onCancelSaveRule()
        break
      case 'derivativeAdjustment':
        await ValuationApi.editDerivativeAdjustmentAutomationRule(
          entityId.value,
          props.currentRuleData?.derivativeAdjustmentAutomationRuleId,
          params
        )
        break
      case 'counterpartyTransformer':
        await TransactionsApi.editCounterpartyTransformerRule(
          entityId.value,
          props.currentRuleData?.counterpartyTransformerId,
          params
        )
        break
      default:
        break
    }
    ElMessage.success(t('message.editSuccess'))
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * @description: 保存自动化规则
 */
const onSaveAutomationRule = async () => {
  if (!ruleFormRef.value) return
  await ruleFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        submitLoading.value = true
        const newList = conditionList.value.map((item: any) => {
          return pick(item, ['operator', 'value', 'type', 'column', 'columnType'])
        })
        const conditions = newList.map((item: any) => {
          const condition = {
            ...item,
            operator: item.operator !== '' ? item.operator : 'EQ',
            value: conditionValueFormat.value(item)
          }
          if (['businessData', 'valuation'].includes(props.activeTab) && item.columnType !== 'CURRENCY_TYPE') {
            delete condition.type
          }
          return condition
        })

        let entryLineAuxiliaryValueList = []
        let entryActions: any = []

        switch (props.activeTab) {
          case 'transfer':
            entryLineAuxiliaryValueList = entryLineAuxiliaryValueFormat()
            break
          case 'businessData':
            entryActions = businessDataEntryFormat()
            break
          case 'trade':
          case 'gainLoss':
          case 'derivativeAdjustment':
            entryActions = otherTabEntryFormat()
            break
          default:
            break
        }

        let params: any = {}
        switch (props.activeTab) {
          case 'transfer':
            params = {
              name: ruleForm.value.name,
              conditions: !isConditionListSingleEmpty.value ? conditions : [],
              journalTypeId: ruleForm.value.journalTypeId,
              type: 'TRANSACTION',
              entryLineAuxiliaryValueMap: entryLineAuxiliaryValueList
            }
            break
          case 'businessData':
            params = {
              name: ruleForm.value.name,
              conditions: !isBusinessDataSingleEmpty.value ? conditions : [],
              businessDataTypeId: ruleForm.value.businessDataTypeId,
              timezone: ruleForm.value.timezone,
              datetime: ruleForm.value.datetime,
              referenceNo: ruleForm.value.referenceNo,
              journalTypeId: ruleForm.value.journalTypeId,
              additionalItem: ruleForm.value.additionalItem,
              journalTypeGroup: ruleForm.value.journalTypeGroup,
              entryActions
            }
            break
          case 'trade':
          case 'gainLoss':
            params = {
              name: ruleForm.value.name,
              conditions: !isConditionListSingleEmpty.value ? conditions : [],
              journalTypeId: ruleForm.value.journalTypeId,
              entryActions
            }
            break
          case 'valuation':
            params = {
              name: ruleForm.value.name,
              conditions: !isBusinessDataSingleEmpty.value ? conditions : [],
              businessDataTypeId: ruleForm.value.businessDataTypeId,
              derivativeType: ruleForm.value.derivativeType,
              action: ruleForm.value.action
            }
            if (['OPTION_REALIZED', 'OPTION_UNREALIZED'].includes(ruleForm.value.action.type)) {
              delete params.action.positionSide
            }
            break
          case 'derivativeAdjustment':
            params = {
              name: ruleForm.value.name,
              conditions: !isConditionListSingleEmpty.value ? conditions : [],
              journalTypeId: ruleForm.value.journalTypeId,
              derivativeType: ruleForm.value.derivativeType,
              entryActions
            }
            break
          case 'counterpartyTransformer':
            params = {
              name: ruleForm.value.name,
              conditions: !isConditionListSingleEmpty.value ? conditions : [],
              counterpartyField: ruleForm.value.counterpartyField,
              identificationCodeName:
                ruleForm.value.counterpartyField === 'IDENTIFICATION_CODE' ? ruleForm.value.identificationCodeName : '',
              extractRegex: ruleForm.value.extractRegex,
              sensitive: ruleForm.value.sensitive
            }
            break
          default:
            break
        }
        if (props.model === 'edit') {
          await onEditAutomationRule(params)
          if (showRuleMessageBox.value) return
        } else {
          await onAddAutomationRule(params)
        }
        emit('resetList', props.model === 'add')
        ruleFormRef.value?.resetFields()
        showRuleDialog.value = false
      } catch (error: any) {
        ElMessage.error(error?.message)
        console.log(error)
      } finally {
        submitLoading.value = false
      }
    }
  })
}

/**
 * @description: 添加条件
 * @param {number} index
 */
const addCondition = (index: number) => {
  if (conditionList.value.length < 11 && !['businessData', 'valuation'].includes(props.activeTab)) {
    conditionList.value.splice(index + 1, 0, {
      index: `${index + 2}${Math.floor(Math.random() * 900) + 100}`,
      operator: 'EQ',
      type: '',
      value: ''
    })
  } else if (
    conditionList.value.length < businessDataColumnOptions.value.length &&
    ['businessData', 'valuation'].includes(props.activeTab)
  ) {
    conditionList.value.splice(index + 1, 0, {
      index: `${index + 2}${Math.floor(Math.random() * 900) + 100}`,
      operator: 'EQ',
      column: '',
      columnType: '',
      type: '',
      value: ''
    })
  }
}

/**
 * @description: entry改变选择值
 * @param {string} key
 * @param {number} index
 * @param {any} value
 */
const onChangeSelectValue = (key: string, index: number, value: any) => {
  entryLines.value[index][key] = value
}

/**
 * @description: 更改条件
 * @param {number} index
 * @param {any} condition
 * @param {string} operating
 */
const onConditionChange = (index: number, condition: any, operating: string = 'edit') => {
  if (operating === 'edit') {
    const id = conditionList.value[index].index
    conditionList.value[index] = condition
    conditionList.value[index].index = id
  } else {
    const newList = filter(conditionList.value, (item: any, i: number) => i !== index)
    conditionList.value = newList
  }
}

const fetchBusinessDataTypeList = async () => {
  try {
    ruleDialogLoading.value = true
    const { data } = await BusinessDataApi.getBusinessDataTypeList(entityId.value)
    businessDataTypeList.value = data
  } catch (error: any) {
    console.error(error)
  } finally {
    ruleDialogLoading.value = false
  }
}

const fetchCounterpartyIdentificationNameList = async () => {
  try {
    ruleDialogLoading.value = true
    const { data } = await TransactionsApi.getCounterpartyIdentificationNameList(entityId.value)
    identifyCounterpartyNameList.value = data
  } catch (error: any) {
    console.error(error)
  } finally {
    ruleDialogLoading.value = false
  }
}

defineExpose({ onCheckRuleDialog })

watch(
  [() => props.currentRuleData, () => showRuleDialog.value, () => ruleDialogLoading.value],
  async () => {
    // 编辑
    if (!isEmpty(props.currentRuleData) && showRuleDialog.value && !ruleDialogLoading.value) {
      const currentData = cloneDeep(props.currentRuleData)
      ruleForm.value.name = currentData.name
      ruleForm.value.journalTypeId = currentData?.journalTypeId ?? ''
      conditionList.value = currentData.conditions.map((item: any, index: number) => {
        const condition: any = {
          index: `${index}`,
          operator: item.operator,
          type: item.type,
          value: item.operator !== 'BETWEEN' && item.type === 'DATETIME' ? item.value[0] : item.value
        }
        // 业务数据和估值规则需要column和columnType
        if (['businessData', 'valuation'].includes(props.activeTab)) {
          condition.column = item.column
          condition.columnType = item.columnType
        }
        return condition
      })
      if (props.activeTab === 'businessData') {
        ruleForm.value.businessDataTypeId = currentData.businessDataTypeId
        ruleForm.value.timezone = currentData.timezone
        ruleForm.value.datetime = currentData.datetime
        ruleForm.value.referenceNo = currentData.referenceNo
        ruleForm.value.additionalItem = currentData.additionalItem
        ruleForm.value.journalTypeGroup = currentData.journalTypeGroup
      }
      if (props.activeTab === 'valuation') {
        ruleForm.value.businessDataTypeId = currentData.businessDataTypeId
        ruleForm.value.derivativeType = currentData.derivativeType
        ruleForm.value.action = {
          datetime: currentData.action.datetime,
          timezone: currentData.action.timezone,
          referenceNo: currentData.action.referenceNo,
          type: currentData.action.type,
          assetName: currentData.action.assetName,
          currency: currentData.action.currency,
          amount: currentData.action.amount,
          positionSide: currentData.action?.positionSide ?? ''
        }
      }
      if (props.activeTab === 'derivativeAdjustment') {
        ruleForm.value.derivativeType = currentData.derivativeType
      }
      if (!['counterpartyTransformer', 'valuation'].includes(props.activeTab)) {
        if (!isGeneralJournalType.value) {
          await journalTypeAuxiliaryPreview()
        } else {
          generalJournalTypeInit(props.currentRuleData.entryActions)
        }
      }

      switch (props.activeTab) {
        case 'transfer':
          if (currentData.entryLineAuxiliaryValueMap.length) {
            entryLineAuxiliaryValueMap.value = entryLineAuxiliaryValueMap.value.map((item: any, index: number) => {
              if (!currentData.entryLineAuxiliaryValueMap[index]?.length) return entryLineAuxiliaryValueMap.value[index]
              return item?.map((auxiliaryValue: any) => {
                const auxiliary = find(currentData.entryLineAuxiliaryValueMap[index], {
                  auxiliaryTypeId: auxiliaryValue.auxiliaryTypeId
                })
                let { value }: any = auxiliary
                if (isEmpty(auxiliary)) return auxiliaryValue
                if (auxiliary.value.type === 'AUXILIARY_ITEM') {
                  value = {
                    type: auxiliary.value?.auxiliaryItemId ? auxiliary.value?.auxiliaryItemId : auxiliary.value.type
                  }
                }
                return {
                  auxiliaryTypeId: auxiliary.auxiliaryTypeId,
                  value
                }
              })
            })
          }
          break
        case 'trade':
        case 'gainLoss':
        case 'derivativeAdjustment':
          if (currentData.entryActions.length) {
            formulaFieldMapData.value = uniqBy(
              currentData.entryActions.reduce((acc: any, cur: any) => acc.concat(cur.formulaFieldMap), []),
              'var'
            )
            currentData.entryActions.forEach((item: any, index: number) => {
              const entry: any = {
                ...entryLines.value[index],
                currency: item.currency,
                amount: item.amount
              }
              const amountFCList = ruleEntryLinesRef.value?.formulaFormatter(item.amountFC)
              if (amountFCList?.length > 1) {
                entry.amountFC = ['FORMULA', item.amountFC]
              } else {
                const auxiliaryMapping = find(item.formulaFieldMap, { var: item.amountFC })
                if (auxiliaryMapping?.type === 'ENTRY_INDEX') {
                  entry.amountFC = [auxiliaryMapping.value]
                } else if (auxiliaryMapping?.type) {
                  entry.amountFC = [auxiliaryMapping.type]
                } else {
                  entry.amountFC = ['FORMULA', item.amountFC]
                }
              }

              entryLineAuxiliaryValueMap.value[index] = entryLineAuxiliaryValueMap.value[index].map(
                (auxiliaryValue: any) => {
                  const auxiliary = find(item.auxiliaryMapping, { auxiliaryTypeId: auxiliaryValue.auxiliaryTypeId })
                  if (isEmpty(auxiliary)) return auxiliaryValue
                  let { value }: any = auxiliary

                  if (props.activeTab === 'derivativeAdjustment') {
                    switch (auxiliary?.auxiliaryType?.type) {
                      case 'ENTITY_ACCOUNT':
                        value = {
                          type: auxiliary.value?.entityAccountId
                            ? auxiliary.value?.entityAccountId
                            : auxiliary.value.type
                        }
                        break
                      case 'COUNTERPARTY':
                        value = {
                          type: auxiliary.value?.counterpartyId ? auxiliary.value?.counterpartyId : auxiliary.value.type
                        }
                        break
                      default:
                        value = {
                          type: auxiliary.value?.auxiliaryItemId
                            ? auxiliary.value?.auxiliaryItemId
                            : auxiliary.value.type
                        }
                        break
                    }
                  } else if (auxiliary.value.type === 'AUXILIARY_ITEM') {
                    value = {
                      type: auxiliary.value?.auxiliaryItemId ? auxiliary.value?.auxiliaryItemId : auxiliary.value.type
                    }
                  }
                  return {
                    auxiliaryTypeId: auxiliary.auxiliaryTypeId,
                    value
                  }
                }
              )
              entryLines.value[index] = entry
            })
          }
          break
        case 'businessData':
          if (currentData.entryActions.length) {
            formulaFieldMapData.value = uniqBy(
              currentData.entryActions.reduce((acc: any, cur: any) => acc.concat(cur.formulaFieldMap), []),
              'var'
            )
            currentData.entryActions.forEach((item: any, index: number) => {
              const entry: any = {
                ...entryLines.value[index]
              }
              entry.currency[0] = item.currencyType
              switch (item.currencyType) {
                case 'BUSINESS_CURRENCY':
                  entry.currency[1] = item.businessCurrency
                  break
                case 'DIRECT_CURRENCY':
                  entry.currency[1] = item.directCurrency
                  entry.directUnderlyingCurrency = item.directUnderlyingCurrency
                  break
                default:
                  entry.currency[1] = entityStore.entityDetail.defaultCurrency
                  break
              }
              const amountList = ruleEntryLinesRef.value?.formulaFormatter(item.amount)
              if (amountList?.length > 1) {
                entry.amount = ['FORMULA', item.amount]
              } else {
                const formulaField = find(item.formulaFieldMap, { var: item.amount })
                if (formulaField?.type === 'BUSINESS_FIELD') {
                  entry.amount = ['BUSINESS_FIELD', formulaField.value]
                }
                if (formulaField?.type === 'CURRENCY_PRICE') {
                  entry.amount = ['CURRENCY_PRICE']
                }
                if (formulaField?.type === 'ENTRY_INDEX') {
                  entry.amount = [formulaField.value]
                }
              }
              const amountFCList = ruleEntryLinesRef.value?.formulaFormatter(item.amountFC)
              if (amountFCList?.length > 1) {
                entry.amountFC = ['FORMULA', item.amountFC]
              } else {
                const formulaField = find(item.formulaFieldMap, { var: item.amountFC })
                if (formulaField?.type === 'BUSINESS_FIELD') {
                  entry.amountFC = ['BUSINESS_FIELD', formulaField.value]
                }
                if (formulaField?.type === 'CURRENCY_PRICE') {
                  entry.amountFC = ['CURRENCY_PRICE']
                }
                if (formulaField?.type === 'ENTRY_INDEX') {
                  entry.amountFC = [formulaField.value]
                }
                if (!formulaField?.type) {
                  entry.amountFC = ['FORMULA', item.amountFC]
                }
              }
              entryLineAuxiliaryValueMap.value[index] = entryLineAuxiliaryValueMap.value[index].map(
                (auxiliaryValue: any) => {
                  const auxiliary = find(item.auxiliaryMapping, { auxiliaryTypeId: auxiliaryValue.auxiliaryTypeId })
                  if (isEmpty(auxiliary)) return auxiliaryValue
                  const data: any = {
                    auxiliaryCode: auxiliary.auxiliaryCode,
                    auxiliaryTypeId: auxiliary.auxiliaryTypeId,
                    data: []
                  }
                  data.data[0] = auxiliary.type === 'PATH' ? 'PATH' : 'DIRECT'
                  switch (auxiliary.auxiliaryCode) {
                    case 'ENTITY_ACCOUNT':
                      data.data[1] = auxiliary.value?.entityAccountId
                      break
                    case 'COUNTERPARTY':
                      data.data[1] = auxiliary.value?.counterpartyId
                      break
                    default:
                      data.data[1] = auxiliary.value?.auxiliaryItemId
                      break
                  }
                  if (auxiliary.type === 'PATH') {
                    data.data[1] = auxiliary.value.columnIndex
                  }
                  return data
                }
              )
              entryLines.value[index] = entry
            })
          }
          break
        case 'counterpartyTransformer':
          ruleForm.value.counterpartyField = currentData.counterpartyField
          ruleForm.value.identificationCodeName = currentData.identificationCodeName ?? ''
          ruleForm.value.extractRegex = currentData.extractRegex
          ruleForm.value.sensitive = currentData.sensitive
          break
        default:
          break
      }

      if (
        ['transfer', 'gainLoss', 'derivativeAdjustment', 'counterpartyTransformer'].includes(props.activeTab) &&
        !currentData?.conditions?.length
      ) {
        conditionList.value = [
          {
            index: '0',
            operator: 'EQ',
            type: '',
            value: ''
          }
        ]
      }

      if (['businessData', 'valuation'].includes(props.activeTab) && !currentData?.conditions?.length) {
        conditionList.value = [
          {
            index: '0',
            column: '',
            operator: 'EQ',
            columnType: '',
            type: '',
            value: ''
          }
        ]
      }
    } else if (isEmpty(props.currentRuleData) && showRuleDialog.value && !ruleDialogLoading.value) {
      // 新增
      ruleForm.value.name = ''
      ruleForm.value.journalTypeId = ''
      ruleForm.value.derivativeType = ''
      ruleForm.value.businessDataTypeId = ''
      ruleForm.value.timezone = 'UTC'
      ruleForm.value.datetime = ''
      ruleForm.value.referenceNo = ''
      ruleForm.value.additionalItem = []
      ruleForm.value.journalTypeGroup = ''
      formulaFieldMapData.value = []
      switch (props.activeTab) {
        case 'businessData':
        case 'valuation':
          conditionList.value = [
            {
              index: '0',
              column: '',
              operator: 'EQ',
              columnType: '',
              type: '',
              value: ''
            }
          ]
          break
        case 'trade':
          conditionList.value = [
            {
              index: '0',
              operator: 'EQ',
              type: 'TRADE_TYPE',
              value: ''
            }
          ]
          break
        default:
          conditionList.value = [
            {
              index: '0',
              operator: 'EQ',
              type: '',
              value: ''
            }
          ]
          break
      }
    }
  },
  { deep: true, immediate: true }
)

watch(
  () => showRuleDialog.value,
  () => {
    if (showRuleDialog.value) {
      nextTick(() => {
        ruleScrollbarRef.value?.setScrollTop(0)
      })
      if (['businessData', 'valuation'].includes(props.activeTab)) {
        if (props.activeTab === 'valuation') {
          ruleForm.value.action = {
            datetime: '',
            timezone: 'UTC',
            referenceNo: '',
            type: '',
            assetName: '',
            positionSide: '',
            currency: '',
            amount: ''
          }
        }
        fetchBusinessDataTypeList()
      }
      if (props.activeTab === 'counterpartyTransformer') {
        ruleForm.value.counterpartyField = 'IDENTIFICATION_CODE'
        ruleForm.value.identificationCodeName = ''
        ruleForm.value.extractRegex = ''
        ruleForm.value.sensitive = false
        fetchCounterpartyIdentificationNameList()
      }
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-automation-rule-dialog {
  width: 850px;
  min-height: 464px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-automation-rule-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 0px 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-automation-rule-dialog-scrollbar__wrap {
      width: 100%;
      box-sizing: border-box;
      padding: 0px 24px;
    }

    .el-input {
      width: 802px;
      height: 36px;
      border-radius: 4px;

      &.elv-rule-form-name {
        height: 44px;
      }

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      &.elv-automation-rule-form-item-line {
        .el-form-item__label {
          position: relative;
          color: #838d95;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          width: 100%;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 704px;
            height: 1px;
            background-color: #dde1e6;
          }
        }

        &.condition .el-form-item__label::after {
          width: 728px;
        }

        &.is-businessData {
          .el-form-item__label::after {
            width: 662px;
          }
        }

        &.is-valuation {
          .el-form-item__label::after {
            width: 622px;
          }
        }

        &.is-businessData-entry {
          .el-form-item__label {
            margin-bottom: 16px;

            &::after {
              width: 737px;
            }
          }
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }

      .elv-rule-form-reviewed {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;

        .el-switch {
          --el-switch-on-color: #2f63eb;
          --el-switch-off-color: #dde1e6;
          width: 38px;
          height: 18px;
          border-radius: 20px;
          margin-left: 10px;
        }
      }

      .elv-rule-form-condition {
        width: 802px;
        min-height: 52px;
        box-sizing: border-box;
        padding: 8px;
        background: #f9fafb;
        border-radius: 5px;
        // :nth-last-child(1) {
        //   margin-bottom: 0px;
        // }
        .elv-automation-rule-conditions:last-child {
          margin-bottom: 0px;
        }

        .elv-rule-form-condition-title {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: 100%;
          padding-bottom: 8px;
          border-bottom: 1px solid #edf0f3;
          margin-bottom: 8px;
        }
      }

      &:not(.is-businessData) .elv-rule-form-event {
        width: 100%;
        height: 94px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        background: #f9fafb;
        border-radius: 5px;

        .elv-rule-form-condition-title {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: 100%;
          padding-bottom: 8px;
          border-bottom: 1px solid #edf0f3;
          margin-bottom: 8px;
        }
      }

      &.is-businessData .elv-rule-form-event {
        width: 100%;
        margin-top: 8px;

        .elv-rule-form-condition-journal-type__title {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 8px;
        }

        .elv-journal-type-drawer-category-select {
          width: 802px;
        }
      }

      .elv-journal-type-drawer-category-select {
        box-sizing: border-box;
        height: 44px;
        width: 786px;
        padding: 10px 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #ffffff;
        border: 1px solid #dde1e6;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        position: relative;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition-property: border, box-shadow;

        > div {
          display: flex;
          flex-direction: column;

          p {
            font-family: 'Plus Jakarta Sans';
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #aaafb6;
            margin-bottom: 8px;
          }

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #0e0f11;
          }
        }

        .is-placeholder {
          color: #838d95;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }

        &:hover {
          cursor: pointer;
          border: 1px solid #5e85eb;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

          svg {
            fill: #5e85eb;
          }
        }

        svg {
          position: absolute;
          right: 12px;
          bottom: 14px;
          fill: #838d95;
          transition: transform 0.3s;
        }
      }
    }

    .elv-automation-rule-form-item__multiple {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100;

      .elv-rule-form-event .elv-journal-type-drawer-category-select {
        width: 532px !important;
      }

      .elv-rule-form-general-journal__type {
        margin-top: 8px;
        margin-left: 8px;

        .el-form-item__label::after {
          display: none;
        }
      }
    }

    .el-select {
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-source-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }

    .elv-automation-rule-entry-item__GSTRate {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 8px;
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 20px;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;

      &.is-disabled {
        background: #edf0f3;
        color: #838d95;
      }
    }
  }
}

.el-popper.elv-journal-type-drawer-category-popper {
  width: fit-content !important;
  padding: 0px;
  min-height: 280px;
  height: fit-content;
  box-sizing: border-box;

  .elv-transactions-drawer-event-option-title {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #edf0f3;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #636b75;

    span {
      font-weight: 600;
      color: #0e0f11;
    }
  }
}
</style>
