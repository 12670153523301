<template>
  <el-select
    v-model="operator"
    popper-class="elv-automation-rule-condition-input-popper"
    :class="[
      props.activeTab === 'businessData'
        ? 'elv-automation-rule-condition-shorter-input'
        : 'elv-automation-rule-condition-short-input'
    ]"
    placeholder="Select"
    @change="() => emit('onChangeType')"
  >
    <el-option v-for="(item, i) in dateTypeOption" :key="i" :label="transformI18n(item.label)" :value="item.value" />
  </el-select>
  <div
    ref="dateSelectRef"
    v-click-outside="onCloseDateTime"
    class="elv-automation-rule-drawer-address-select"
    :class="{ 'is-shorter': props.activeTab === 'businessData' }"
    @click="onOpenDateTime"
  >
    <div class="elv-automation-rule-drawer-time-value">
      <template v-if="operator === 'BETWEEN'">
        <template v-if="value">
          <el-tooltip
            ref="elvTableTipsRef"
            :show-arrow="false"
            effect="light"
            placement="top"
            :disabled="props.activeTab !== 'businessData'"
            popper-class="elv-table-tips"
            overlay-classname="elv-table-tips"
            :show-after="500"
          >
            <div>
              <span>{{ dateFormatting(value[0], 'date') }}</span>
              <span>&nbsp;- {{ dateFormatting(value[1], 'date') }}</span>
            </div>
            <template #content>
              <div class="elv-table-tips-content">
                <span>{{ dateFormatting(value[0], 'date') }}</span>
                <span>&nbsp;- {{ dateFormatting(value[1], 'date') }}</span>
              </div>
            </template>
          </el-tooltip>
        </template>
      </template>
      <span v-else>{{ value ? dateFormatting(isArray(value) ? value[0] : value, 'date') : '' }}</span>
    </div>
    <SvgIcon name="events-arrow" width="16" height="16" fill="#a8abb2" :style="{ transform: arrowRotate }" />
  </div>

  <el-date-picker
    ref="datePickerPopoverRef"
    v-model="value"
    popper-class="elv-automation-rule-date-popper"
    :virtual-ref="dateSelectRef"
    virtual-triggering
    :type="operator === 'BETWEEN' ? 'datetimerange' : 'date'"
    value-format="YYYY-MM-DD"
    :clearable="false"
    range-separator="To"
    start-placeholder="Start date"
    end-placeholder="End date"
    @change="onChangeDate"
  />
</template>

<script setup lang="ts">
import { isArray } from 'lodash-es'
import { dateFormatting } from '@/lib/utils'
import { $t, transformI18n } from '@/i18n/index'
import { ClickOutside as vClickOutside } from 'element-plus'

const props = defineProps({
  activeTab: {
    type: String,
    default: ''
  }
})

const dateSelectRef = useTemplateRef<HTMLElement>('dateSelectRef')
const datePickerPopoverRef = ref()
const visibleDatePopover = ref(false)
const arrowRotate = ref('rotate(90deg)')

const value = defineModel<any>('value', { required: true })
const operator = defineModel<any>('operator', { required: true })

const emit = defineEmits(['onChangeType'])

const dateTypeOption = reactive([
  { label: $t('common.on'), value: 'ON' },
  { label: $t('common.before'), value: 'BEFORE' },
  { label: $t('common.after'), value: 'AFTER' },
  { label: $t('common.isBetween'), value: 'BETWEEN' }
])

const onOpenDateTime = () => {
  visibleDatePopover.value = !visibleDatePopover.value
  arrowRotate.value = visibleDatePopover.value ? 'rotate(-90deg)' : 'rotate(90deg)'
  if (visibleDatePopover.value) {
    unref(datePickerPopoverRef)?.handleOpen?.()
  } else {
    unref(datePickerPopoverRef)?.handleClose?.()
  }
}

const onCloseDateTime = () => {
  visibleDatePopover.value = false
  arrowRotate.value = 'rotate(90deg)'
  unref(datePickerPopoverRef)?.handleClose?.()
}

const onChangeDate = () => {
  visibleDatePopover.value = false
  arrowRotate.value = 'rotate(90deg)'
}
</script>

<style lang="scss">
.elv-automation-rule-date-popper {
  &.el-popper {
    transform: translateY(-11.5px);
    border: 1px solid #e4e7eb;
    box-shadow:
      0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }

  .el-picker-panel__body-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .el-date-range-picker__content.is-left {
    border-right: 0px;
  }

  .el-date-range-picker .el-picker-panel__content {
    padding: 8px;
  }

  .el-date-picker {
    width: 237px;

    .el-picker-panel__content {
      width: 221px;
      margin: 0;
      margin-top: 6px;

      .el-date-table {
        th {
          min-width: 31.57px;
          height: 16px;
          border-bottom: 0px;
          padding: 0px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 11px;
          line-height: 11px;
          color: #aaafb6;
        }

        tr {
          color: #636b75;
        }

        td {
          padding: 0px;
          width: 31.57px;
          height: 27.8px;
          border-radius: 2px;

          &.today .el-date-table-cell__text {
            color: #1e2024;
          }

          &.available:hover {
            border-radius: 2px;
            background: #edf0f3;

            .el-date-table-cell__text {
              color: #1e2024;
              font-weight: 600;
            }
          }

          &.next-month {
            display: none;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          &.prev-month {
            visibility: hidden;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          .el-date-table-cell {
            padding: 0px;
            width: 31.57px;
            height: 27.8px;

            .el-date-table-cell__text {
              width: 31.57px;
              height: 27.8px;
              font-family: 'Barlow';
              font-weight: 500;
              font-size: 13px;
              line-height: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0px;
            }
          }

          &.in-range .el-date-table-cell {
            background-color: #fff;
          }

          &.current .el-date-table-cell {
            margin-left: 0px;
            border-radius: 0px;

            .el-date-table-cell__text {
              color: #0e0f11;
              font-weight: 600;
              background-color: #fff;
              box-sizing: border-box;
              border-bottom: 1px solid #1343bf;
            }
          }
        }
      }
    }

    .el-picker-panel__body {
      padding: 8px;
    }

    .el-date-picker__header {
      margin: 0;
      padding: 0;
    }

    .el-date-picker__header-label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      color: #aaafb6;
      padding: 0;
      position: relative;
      top: 4px;

      &:nth-child(3) {
        font-weight: 700;
        color: #1e2024;
        margin-left: 4px;
      }
    }
  }

  .el-date-range-picker {
    &.has-time {
      width: 392px;
      min-height: 245px;
      max-height: 273px;
      overflow: hidden;
      background: #ffffff;
    }

    .el-picker-panel__body {
      min-width: 392px;
    }
  }

  .el-picker-panel__icon-btn {
    margin-top: 10.5px;
  }

  .el-date-range-picker__header,
  .el-date-picker__header {
    height: 34px;
    border-bottom: 1px solid #edf0f3;

    div {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 34px;
      color: #1e2024;
      margin: 0px;
    }
  }

  .el-picker-panel__sidebar {
    display: flex;
    border: 0px;
    bottom: -30px;
    left: 8px;
    padding-top: 0;
    z-index: 1;
    height: 21px;
    width: auto;
    top: auto;

    .el-picker-panel__shortcut {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 4px 10px;
      min-width: 65px;
      max-width: 73px;
      width: auto;
      height: 21px;
      border: 1px solid #d0d4d9;
      border-radius: 11px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #636b75;
      margin-right: 4px;

      &:hover {
        background: #e6edff;
        border: 1px solid #5e85eb;
        color: #1753eb;
        font-weight: 600;
      }
    }
  }

  .el-picker-panel__body {
    margin-left: 0;
    height: auto;

    .el-date-range-picker__time-header {
      display: none;
    }
  }

  .el-date-table {
    th {
      min-width: 25.71px;
      height: 16px;
      border-bottom: 0px;
      padding: 0px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      color: #aaafb6;
    }

    tr {
      color: #636b75;
    }

    td {
      padding: 0px;
      width: 25.71px;
      height: 27.8px;
      border-radius: 2px;

      &.today .el-date-table-cell__text {
        color: #1e2024;
      }

      &.available:hover {
        border-radius: 2px;
        background: #edf0f3;

        .el-date-table-cell__text {
          color: #1e2024;
          font-weight: 600;
        }
      }

      &.next-month {
        display: none;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      &.prev-month {
        visibility: hidden;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      .el-date-table-cell {
        padding: 0px;
        width: 25.71px;
        height: 27.8px;

        .el-date-table-cell__text {
          width: 25.71px;
          height: 27.8px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px;
        }
      }

      &.in-range .el-date-table-cell {
        background-color: #fff;
        border-bottom: 1px solid #1343bf;
      }

      &.start-date .el-date-table-cell {
        margin-left: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }

      &.end-date .el-date-table-cell {
        margin-right: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          border-right: 0px;
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }
    }
  }

  .el-picker-panel__footer {
    padding: 0px;
    margin: 0 8px;
    padding-top: 8px;
    box-sizing: border-box;
    height: 37px;

    .el-picker-panel__link-btn {
      height: 21px;
      margin-top: -7px;
    }
  }
}
</style>
